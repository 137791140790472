
import React, { useState, useEffect } from 'react';
import { graphql } from "gatsby"
import Link  from '@mui/material/Link';
import PageLayout from "../components/PageLayout"
import Seo from "../components/Seo"
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import {
    useLocation
  } from "@reach/router";

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

const Search = ({data}) => {
  const [terms, setTerms] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  let query = useQuery().get("q");
  useEffect(() => {
    var termsUrl = "https://www.create.ab.ca/wp-json/wp/v2/posts/?per_page=100&categories=115&orderby=relevance&order=asc&search=";
    termsUrl = termsUrl + query;
    fetch(termsUrl)
    .then(res => res.json())
    .then(
        (result) => {
            setIsLoaded(true);

            setTerms(result);
        },
        (error) => {
        }
    )
   });

  return (
    <PageLayout>
      <Seo title="Search" />
    <Box sx={{ pt: 2 }} >
      <form >
        <TextField variant="outlined"
          size="small"
          name="q"
          placeholder="Search..."
          sx={{ pr: 1 }}
          inputProps={{ 'aria-label': 'search' }}
          defaultValue={query}

        />
        <Button type="submit" variant="contained" sx={{color: 'white'}} >Search</Button>
      </form>
    </Box>
    <h3>Search for '{query}'</h3>
    {!isLoaded  &&
      <div>Searching...</div>
    }
    {terms.length === 0 && isLoaded &&
      <div>No terms found for search.</div>
    }
    <List dense disablePadding>
                {
                    terms.map((term, i) =>
                        <ListItem disableGutters><Link sx={{color:"primary.main", fontWeight: "normal"}} href={"/term/"+term.slug}>{term.title.rendered}</Link></ListItem>
               
                  )
                }
                </List>
    <Container >&nbsp;</Container>
  </PageLayout>
  );
 
  
}

export default Search

export const zpageQuery = graphql`
query None {
  categories: allWpPage(
    filter: {wpParent: {node: {slug: {eq: "headstart-home"}}}}
    sort: {order: ASC, fields: menuOrder}
  ) {
    edges {
      node {
        id
        uri
        title
        content
        parentId
        databaseId
        uri
        headstartPages {
          abstract
        }
        featuredImage {
          node {
            mediaItemUrl
          }
        }
      }
    }
  }
  home: allWpPage(filter: {slug: {eq: "headstart-home"}}) {
    edges {
      node {
        id
        uri
        title
        content
        parentId
        databaseId
        uri
        headstartPages {
          abstract
        }
      }
    }
  }
}
`
